import axios, { AxiosRequestConfig, Canceler } from 'axios'
import { Storage, browserType, deleteEmpty } from '@bihu/common-js'
import { RESPONSE_CODE } from '@/constants/common'
import { TOKEN } from '@/constants/storage'
import { ApiResponse } from '@/types/apis/index'
import { ExtraAxiosRequestConfig } from '../types/axios-extend'
import event from '@/event'
import { TOKEN_INVALID } from '@/event/user'
import { Toast } from 'antd-mobile'

// 接口前缀
type Prefix = Record<typeof process.env.REACT_APP_API_ENV | 'mock', string>

const PREFIX: Prefix = {
  mock: 'https://yapi.comliq.net/mock/247/', // yapi mock 地址
  development: 'https://qa.xingchentongfu.cn/', // qa环境
  // development: 'http://192.168.2.122:8063/', // qa环境
  production: 'https://kongdao.xingchentongfu.cn/' // 生产环境
}

export const interfacePrefix:string = PREFIX[process.env.REACT_APP_API_ENV] // 接口前缀


// 使用实际测试环境域名，将无法被铺抓到
// const interfacePrefix = '/api/' // 接口前缀

// 默认参数
const customDefault: ExtraAxiosRequestConfig = {
  showError: true,
  deleteEmpty: true,
  returnOrigin: false,
  withoutCheck: false,
  isCancelRequest: true,
  mock: false,
}

// 创建请求器
const service = axios.create({
  baseURL: '',
  timeout: 30000, // 请求超时时间
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  },
  ...customDefault
})


// 添加请求拦截器
service.interceptors.request.use(request => {

  // 如果参数是 formData格式
  if (request.dataFormat === 'formData') {
    request.headers && (request.headers['Content-Type'] = 'multipart/form-data')
    let params = new FormData() // 当前为空
    const { data } = request
    for (const key in data) {
      params.append(key, data[key])
    }
    request.params = params
  }

  // 对请求参数进行处理，清除空参数
  if (request.data && request.deleteEmpty) {
    request.data = deleteEmpty(request.data)
  }
  if (request.params && request.deleteEmpty) {
    request.params = deleteEmpty(request.params)
  }

  // 检测接口，根据环境自动切换前缀
  if (request.url && request.url.indexOf('http') === -1) {
    if (request.url[0] === '/') {
      request.url = request.url.substring(1)
    }
    request.url = `${process.env.REACT_APP_API_ENV !== 'production' && request.mock ? PREFIX.mock : interfacePrefix}${request.url}`
  }


  // 若有做鉴权token，需要请求头自动加上token
  request.headers && (request.headers.accessToken = Storage.get(TOKEN))
  // 判断平台来源-是否有钱助手
  const platFormSource = sessionStorage.getItem('FLATFORM_SOURCE')
  if (platFormSource === 'YqzsApp') {
    request.headers && (request.headers.PLATFORM_PARAM = JSON.stringify({
      appType: 3,
      channel: 1
    }))
  }

  return request
},
error => {
  if (error?.config?.showError) {
    Toast.clear()
    Toast.show({
      content: error.toString(),
      icon: 'fail'
    })
  }
  return Promise.reject(error)
}
)

// 判断是否 token 过期
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTokenInvalid = (res: ApiResponse<any>) => {
  if (!res) {
    return false
  }
  return res.code === RESPONSE_CODE.TOKEN_OVERDUE || res.code === RESPONSE_CODE.TOKEN_ERROR
}
// token 失效处理
const handleTokenInvalid = () => {
  event.emit(TOKEN_INVALID)
}

// 辅助函数，用于判断一个值是否接口响应
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isApiResponse(res: any): res is ApiResponse<any> {
  return !!res && typeof res.code === 'number' && typeof res.msg === 'string'
}

// 添加响应拦截器
service.interceptors.response.use(res => {
  const { config, data: result } = res // 请求时携带的配置项
  // console.log('添加响应拦截器', res)

  if (config.withoutCheck) { // 不进行状态状态检测
    return result
  } else if (result.code === RESPONSE_CODE.SUCCESS) { // 接口正常调用
    return config.returnOrigin ? result : result.data // 是否返回原始数据
  } else if (isTokenInvalid(result)) { // token失效，登录过期
    // 弹出提示前先销毁上一个，避免一个页面同时显示多个提示
    Toast.clear()
    // Toast.show({
    //   content: '授权已过期，请重新登录',
    //   icon: 'fail'
    // })
    handleTokenInvalid()
    return Promise.reject(result.msg)
  } else if (result.code === RESPONSE_CODE.OTHER_LOGIN) { // 账号被其他人登录
    Toast.clear()
    Toast.show({
      content: '账号已被他人登录，请重新登录',
      icon: 'fail'
    })
    handleTokenInvalid()
    return Promise.reject(result.msg)
  } else { // 其他错误
    const msg = result.msg || '服务器出错了～'
    if (config.showError) {
      // Toast.clear()
      Toast.show({
        content: msg,
        duration: 3000
      })
    }
    return Promise.reject(result)
  }
},
error => { // 非 2xx 状态入口
  if (error.config?.showError) {
    Toast.clear()
    // Toast.show({
    //   content: '网络繁忙，请刷新重试',
    //   icon: 'fail',
    // })
  }
  // 判断是取消的请求不要弹出错误提示
  if (error.message.startsWith('终止请求')) {
    return Promise.reject(error)
  }
  return Promise.reject(error)
}
)

// object对象存放每次 new CancelToken 生成的方法
// eslint-disable-next-line no-unused-vars
const source: Record<string, Canceler> = {}
// 每次请求前都会把path放在此数组中，响应成功后清除此请求path
const requestList: string[] = []
// 定义取消方法
function cancelRequest(path: string, allCancel = false) {
  // 请求列表里存在此path，即发起重复请求，把之前的请求取消掉
  if (path && requestList.includes(path) && typeof source[path] === 'function') {
    source[path](`终止请求: ${path}`)
  } else if (!path && allCancel) {
    // allCancel 为 true 则请求列表里的请求全部取消
    requestList.forEach(requestPath => {
      source[requestPath]('批量终止请求')
    })
  }
}
const isIe = browserType?.indexOf('IE') !== -1
// 这里只做post get请求封装，大家可以自己封装其他请求方法
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function requestFn<T, R>(method:string, path:string, data:any = {}, options = {}) {
  let newOptions = {
    ...customDefault,
    ...options
  } // 注意这里不是多层深拷贝
  // 取消上一次请求
  if (requestList.length) {
    cancelRequest(path)
  }
  // 设置isCancelRequest为ture, 请求前将path推入requestList
  if (newOptions.isCancelRequest) {
    requestList.push(path)
  }

  if (method === 'post') {
    return service.post<T, R>(path, data, {
      cancelToken: new axios.CancelToken(c => {
        source[path] = c
      }),
      ...newOptions
    })
  } else { // get请求
    // 防止ie浏览器get请求使用缓存，在请求参数上加一个时间戳
    if (isIe) {
      data === null ? (data = { t: new Date().getTime() }) : (Object.assign(data, { t: new Date().getTime() }))
    }
    return service.get<T, R>(path, {
      params: data,
      cancelToken: new axios.CancelToken(c => {
        source[path] = c
      }),
      ...newOptions
    })
  }
}

export const api = {
  axios: service, // 原始 axios 对象

  get: <T, R = T>(path: string, data: unknown, config?: AxiosRequestConfig) => requestFn<T, R>('get', path, data, config),
  // 统一delete方法采用url传参
  delete: <T, R = T>(path: string, data: unknown, config?: AxiosRequestConfig) => service.delete<T, R>(path, {
    params: data,
    ...config
  }),
  post: <T, R = T>(path: string, data: unknown, config?: AxiosRequestConfig) => requestFn<T, R>('post', path, data, config),
  put: <T, R = T>(path: string, data: unknown, config?: AxiosRequestConfig) => service.put<T, R>(path, data, {
    ...customDefault,
    ...config
  })
}

export default api
