/* eslint-disable no-magic-numbers */
// 注册
import React, { useEffect, useState } from 'react'
import styles from './register.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import activityApi from '@/apis/activity'
import indexApi from '@/apis'
import fingerprintjs from '@fingerprintjs/fingerprintjs'
import Skin5 from './components/skin5/skin5'
import Skin6 from './components/skin6/skin6'
import Skin7 from './components/skin7/skin7'
import Skin360 from './components/skin-360/skin-360'
import Skin8 from './components/skin8/skin8'
import { Storage, formatTime } from '@bihu/common-js'
import { CURRENT_SKIN_INDEX, ENTER_AGREEMENT, TOKEN } from '@/constants/storage'
import useFirstOrAfter from '@/hooks/use-first-or-after'
// import DetainmentPopup from './components/detainment-popup/detainment-popup'
import LoginPopup from './components/login-popup/login-popup'
interface Props {
  type?: 'getQuota', // 领取额度
}

const adChannelType = {
  register: 1,
  getQuota: 2
}

const Register: React.FC<Props> = props => {
  const navigate = useNavigate()

  const { type } = props

  // 当前皮肤号
  const [currentSkinIndex, setCurrentSkinIndex] = useState<number>(3)

  // 是否显示弹窗
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)

  const [isSkin6, setIsSkin6] = useState<boolean>(false)
  const [isSkin7, setIsSkin7] = useState<boolean>(false)
  const [isSkin8, setIsSkin8] = useState<boolean>(false)
  const [isSkin360, setIsSkin360] = useState<boolean>(false)
  const [showSkin5Page, setShowSkin5Page] = useState<boolean>(false)
  const [showPage, setShowPage] = useState<boolean>(false)
  const [codeDetail, setCodeDetail] = useState<any>()

  // 生成随机数范围
  const randomNum = (minNum:number, maxNum:number) => {
    return parseInt((Math.random() * (maxNum - minNum + 1) + minNum).toString(), 10)
  }

  const originalPushState = window.history.pushState
  // type myPushState = typeof window.history.pushState
  // eslint-disable-next-line no-shadow
  window.history.pushState = function myPushState(...args) {
    // console.log('pushState', args, window.history.state)
    return originalPushState.apply(this, args)
  }

  useFirstOrAfter(() => {
    const skinIndex = Storage.get(CURRENT_SKIN_INDEX)

    // 有缓存的皮肤index，则+1
    if (skinIndex) {
      let temp = skinIndex + 1
      if (temp > 4) {
        temp = 1
      }

      setCurrentSkinIndex(temp)
      Storage.set(CURRENT_SKIN_INDEX, temp)
    } else { // 没有缓存皮肤index, 则随机
      let tempRandomNum = randomNum(1, 4)
      setCurrentSkinIndex(tempRandomNum)
      Storage.set(CURRENT_SKIN_INDEX, tempRandomNum)
    }

    localStorage.removeItem('productBack')
    const code:any = search.get('adChannelCode')
    if (code) {
      setAdChannelCode(code)
      localStorage.setItem('ad_channel_code', code)
      localStorage.setItem('hostName', '星辰钱包')
    }
    // 判断是否分期乐渠道
    if (code === '9o0w2B') {
      if (!Storage.get('isRefreshLoadPage') && !search.get('refresh')) {
        Storage.set('isRefreshLoadPage', true)
        window.location.href = `${document.URL}&refresh=1`
        return
      } else {
        Storage.set('isRefreshLoadPage', false)
      }

      // 监听浏览器回退事件
      window.addEventListener('popstate', back, false)
      // window.onpopstate = back
      window.history.pushState(null, 'null', document.URL)

      return () => {
      // 回退事件只用于当前组件，则需要在组件销毁时把回退事件销毁
        window.removeEventListener('popstate', back, false)
      // window.onpopstate = null
      }
    }
  }, () => {
  // eslint-disable-next-line no-console
    console.log('afterFn')
  }, [])

  // 返回
  const back = () => {
    const productBack = localStorage.getItem('productBack')
    const isShowPopupStorage = Storage.get('isShowPopup')

    try {
      if (isShowPopupStorage) {
        Storage.set('isShowPopup', false)
        setIsShowPopup(false)
        navigate(-1)
        // if (isShowPopupStorage) {
        //   Storage.set('isShowPopup', false)
        //   setIsShowPopup(false)
        //   return
        // }
        // navigate(-1)

      } else {
        //  阻止浏览器回退事件
        setIsShowPopup(true)
        Storage.set('isRefreshLoadPage', false)
        // window.history.pushState(null, 'null', document.URL)
        // localStorage.setItem('productBack', formatTime(Date.now(), 'YYYY-MM-DD'))
        Storage.set('isShowPopup', true)
      }
    } catch (error) {
    // eslint-disable-next-line no-console
      // console.log(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const [search] = useSearchParams()

  // 渠道编码
  const [adChannelCode, setAdChannelCode] = useState('')

  const judgeLoginAfterPage = () => {
    if (type === 'getQuota' && adChannelCode !== 'YxNj9v') {
      window.location.href = `/fill-information?adChannelCode=${adChannelCode}` //如果访问链接是getQuota类型，就跳转表单填写页
    } else {
      window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify({ isNotFormReq: true }))}`
    }
  }

  // 有token状态下，判断下一页的跳转
  const judgeNextPage = async() => {
    // 如果是注册-下载模式，直接跳转下载页
    if (codeDetail.linkType === adChannelType.register) {
      window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify({ isNotFormReq: true, isSpecialListCity: true }))}`
      return
    }
    // 获取表单填写状态  submitStatus >>> 0:未填写 1:已填写未提交 2: 已授权
    const res:any = await indexApi.getApplyStatus()
    if (res.code === 200) {
      // 判断是否华为手机(针对个别渠道会用到，如分期乐)
      let isHuaWeiPhone = false
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.indexOf('huawei') !== -1) {
        isHuaWeiPhone = true
      }
      if (res.data && res.data?.submitStatus === 1) {
        // 获取上回提交信息
        const submitResult:any = await indexApi.getFomrSubmitStatus({ adChannelCode })
        if (submitResult.productName === null) {
          window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({
            isNotFormReq: true,
            rePageShowType: adChannelCode === '9o0w2B' && isHuaWeiPhone ? 2 : 0,
            revisit: true
          }))}`)
          return
        }

        if (submitResult.thirdpartyType === 5) {
          window.location.replace(`/fill-information/authorize?options=${encodeURIComponent(JSON.stringify(submitResult))}&adChannelCode=${adChannelCode}&isHuaWeiPhone=${isHuaWeiPhone}&applyLimit=${res.data.applyLimit}`)
          return
        }

        // 跳转授权页
        let tempOptions = {
          adChannelCode,
          isHuaWeiPhone,
          applyLimit: res.data.applyLimit,
          workCity: res.data.workCity,
          rqResult: submitResult
        }
        window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(tempOptions))}&applyLimit=${res.data.applyLimit}&isHuaWeiPhone=${isHuaWeiPhone}`)
      } else if (res.data && res.data?.submitStatus === 10) {
        // rematchResult: 1-不需要重新匹配 2-重新匹配不到结果 3-重新匹配到结果
        const reMatchResult:any = await indexApi.getFomrSubmitStatus({ adChannelCode })
        let tempOptions:any = {}
        // 如果重现匹配并且有结果，跳至动图页
        if (reMatchResult.rematchResult === 3) {
          // 携带授权数据跳转重新匹配结果页
          tempOptions = {
            adChannelCode,
            isHuaWeiPhone,
            applyLimit: res.data.applyLimit,
            workCity: res.data.workCity,
            rqResult: reMatchResult
          }
          window.location.replace(`/fill-information/rematch-waiting?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
          return
        }

        // 否则取上回的结果，跳至下载页
        tempOptions = JSON.parse(JSON.stringify(reMatchResult))
        tempOptions.isNotFormReq = true // 标记为非表单请求方式的跳转
        tempOptions.rePageShowType = adChannelCode === '9o0w2B' && isHuaWeiPhone ? 2 : 0
        tempOptions.revisit = true // 标记为再访
        window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
      } else {
        // 来到这说明未提交表单
        judgeLoginAfterPage()
      }
    } else {
      setShowPage(true)
    }
  }

  const clearH5TitleAndIcon = () => {
    // 清空标题
    document.title = ' '

    // 清空favicon
    const favicons = document.querySelectorAll('link[rel="icon"],link[rel="shortcut icon"],link[rel="apple-touch-icon"]')
    // 移除现有的所有favicon标签
    favicons.forEach((link:any) => link.parentNode.removeChild(link))

    // 添加一个新的透明favicon
    const favicon = document.createElement('link')
    favicon.rel = 'icon'
    favicon.href = 'data:image/gifbase64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
    document.head.appendChild(favicon)

    // 添加一个新的透明apple-touch-icon
    const appleIcon = document.createElement('link')
    appleIcon.rel = 'apple-touch-icon'
    appleIcon.href = 'data:image/gifbase64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
    document.head.appendChild(appleIcon)
  }

  const handleMainFlow = () => {
    // 获取平台来源
    const platFormSource = search.get('platformSource')
    if (platFormSource) {
      sessionStorage.setItem('FLATFORM_SOURCE', platFormSource)
    }

    const hasToken = Storage.get(TOKEN)
    // 判断是否有token(360渠道、拍拍贷渠道对token的判断单独放在各自对应的皮肤组件里)
    // const sepcialChannels = ['hBSfjw', 'ZoJERy', 'rWMspl', 'ZgvvGJ', 'pmeLeA']
    if (hasToken && !codeDetail.location) { // 有token并且不需要获取定位，接着执行下一步
      judgeNextPage()
    } else {
      setShowPage(true)
    }

    if (adChannelCode) {
      // 判断如果渠道码是NRxJgP，则使用皮肤6
      if (adChannelCode === 'NRxJgP') {
        setIsSkin6(true)
      }
      // 判断如果渠道码是hBSfjw，则使用360皮肤
      if (adChannelCode === 'hBSfjw') {
        setIsSkin360(true)
      }
      // 判断如果渠道码是还呗系列，则使用皮肤8
      const skin8CodeList = ['9N9JE8', 'FLmCzN', '7wQBe8']
      if (skin8CodeList.indexOf(adChannelCode) !== -1) {
        setIsSkin8(true)
      }

      setTimeout(() => {
        setShowSkin5Page(true)
      }, 10)

      // 判断皮肤7的渠道码
      const skin7CodeList = ['mrCGvU', '4eXawu', 'CowI4M', 'kU6KgW', 'sE02eN']
      if (skin7CodeList.indexOf(adChannelCode) !== -1) {
        const bdVid = search.get('bd_vid') //百度渠道才会携带的标识
        // 如果是百度渠道来的，将登录页的全路径保存到本地，到了授权页时通过授权接口将它传给后端
        if (bdVid) {
          localStorage.setItem('baiduVistLink', window.location.href)
        }
        localStorage.setItem('isBaiduChannels', 'true')
        setIsSkin7(true)
        // 清空网址的icon和文字
        clearH5TitleAndIcon()
      } else {
        localStorage.removeItem('isBaiduChannels')
        localStorage.removeItem('baiduVistLink')
      }
    }
  }

  // 插入百度脚本(仅应用在百度渠道)
  useEffect(() => {
    if (!isSkin7) {
      return
    }
    const script = document.createElement('script')
    script.src = `https://tjs.wukongtj.com/Public/productjs/659646b94130b.js?${Math.random()}`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [isSkin7])

  useEffect(() => {
    if (currentSkinIndex && adChannelCode) {
      // 获取到浏览器指纹后调用上报接口
      fingerprintjs.load().then(fp => fp.get())
        .then(result => {
          const { visitorId } = result

          activityApi.reportAdChannelClick({
            adChannelCode,
            fingerprint: visitorId,
            abTestType: isSkin6 ? 6 : 5 //固定皮肤数
            // abTestType: currentSkinIndex //随机皮肤数
          })
        })
    }
  }, [currentSkinIndex, adChannelCode])

  useEffect(() => {
    const getChannelDetail = async() => {
      const codeDetailRes:any = await activityApi.getChannelCodeDetail(adChannelCode)
      //广告渠道状态，1-正常，0-禁用
      if (codeDetailRes && codeDetailRes.channelStatus === 0) {
        window.location.href = '/failure'
        return
      }
      setCodeDetail(codeDetailRes)
    }
    if (adChannelCode) {
      getChannelDetail()
    }
  }, [adChannelCode])

  useEffect(() => {
    if (codeDetail) {
      // 执行页面主流程
      handleMainFlow()
    }
  }, [codeDetail])

  return (
    <div className={styles.registerPage}>
      {
        showPage ? <>
          <Skin360 type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
        </> : <>
          <div id="root">
            <div id="loading-box">
              <div className={styles.ballClipRotateMultiple}>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default Register
