// 产品相关接口
import { api } from '@/utils/axios'
import { EntranceType } from '@/types/apis/index'

export interface ProductResponse {
    id: number, //主键
    productName: string, //产品名称
    productLogo: string, //产品logo
    loansLimitMin: number, //借款额度下限
    loansLimitMax: number, //借款额度上限
    monthlyInterestRate: number, //月利率
    showDetailPage: 0|1, //是否展示详情页，0-否、1-展示
    showHomePageTop: 0|1, //是否首页顶部推荐，0-否、1-推荐
    showHomePageTopWeight: number, //首页顶部推荐权重值
    showHomePageWeight?: number, //首页推荐权重值
    showFixation: 0|1, //是否固定展示，0-否、1-固定
    createdAt: string //创建时间
}

export interface ProductListParams{

    /** 产品类型，1-极速贷款、2-大额贷款 */
    productType?: number

    /** 是否首页热门推荐，1-热门推荐 */
    showHomePage?: number

    /** 下拉分页标识，上次列表最后一个产品id */
    lastProductId?: number

    /** 下拉分页标识，上次列表最后一个产品权重 */
    lastShowHomePageWeight?: number

    appH5?: number, //数据源类型，不传默认安卓APP 2-H5 3-有钱掌柜(已废弃) 4-有钱助手(H5)
}

export interface ProductListResponse extends ProductResponse {
    productType: number, //产品类型，1-极速贷款、2-大额贷款
    applyLimit: number, //每日申请已满，0-否，1-已满
    monthlyInterestRate: number, //月利率
    totalApplyNum: number, //已申请人数
    lendingRate?: number, // 放款利率
}

export interface ProductDetailResponse {
    id: number, //主键
    productName: string, //产品名称
    productType: number, //产品类型，1-极速贷款、2-大额贷款
    introduction: string, //产品介绍
    applyLimit: number, //每日申请已满，0-否，1-已满
    productLogo: string, //产品logo
    loansLimitMin: number, //借款额度下限
    loansLimitMax: number, //借款额度上限
    monthlyInterestRate: number, //月利率
    productCompany: string, //产品发行的公司主体
    contactNumber: string, //联系客服电话
    askCondition: string, //要求条件
    createdAt: string //创建时间
}

interface ApplyParams{
    productId: number, //产品id
    loansAmount?: number, //贷款金额
    loansDuration?: number, //贷款时长，单位月
    entranceType?: EntranceType // 入口类型，1-首页中插，2-首页弹窗、3-开屏、4-顶部推荐、5-首页列表、6-产品列表 7-随机推荐
    appH5?: number
}

export interface ApplyResponse {
  applyStatus: number, // 申请状态，0-产品销售火爆稍后再试、1-申请成功、2-已经申请过可直接跳转
  thirdpartyTargetUrl: string // 第三方跳转目标地址
}

const productApi = {
  // 获取顶部推荐产品
  topRecommend() {
    return api.get<ProductListResponse[]>('/api/product/topRecommend', null, {
      // mock: true
    })
  },
  // 获取热门推荐产品列表
  productList(body?: ProductListParams) {
    return api.get<ProductListResponse[]>('/api/product/productList', body, {
      // mock: true
    })
  },
  // 获取产品详情
  detail(body: { productId: number }) {
    return api.get<ProductDetailResponse>('/api/product/detail', body, {
      // mock: true
    })
  },
  // 获取随机推荐产品
  randomRecommend(body:any) {
    return api.get<ProductListResponse>('/api/product/randomRecommend', body, {
      // mock: true
    })
  },
  // 发起产品申请
  apply(body: ApplyParams) {
    return api.post<ApplyResponse>('/api/product/apply', body, {
      // mock: true
    })
  },
  // 查询用户今天申请的产品
  listTodayApplyProduct() {
    return api.get<ProductListResponse[]>('/api/product/listTodayApplyProduct', null, {
      // mock: true
    })
  },
}
export default productApi