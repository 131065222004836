import { useState, useEffect } from 'react'
import yuanxinLogo from '@imgs/yuanxin-logo.png'
import youqianlaiLogo from '@imgs/youqianlai-logo.png'
import youqianlaiText from '@imgs/youqianlai-text.png'

interface hostNameObjType {
    appName: string,
    companyName: string,
    filing:string,
    logo: string,
    appType: number,
    logoText: string
  }

// 获取域名
function useGetHostnameObj() {
  const [hostname, setHostname] = useState('')

  useEffect(() => {
    setHostname(window.location.hostname)
  }, [])

  const defaultObj:hostNameObjType = {
    appName: '星辰钱包',
    companyName: '成都星辰同福企业管理有限公司',
    filing: '',
    logo: '',
    appType: 1,
    logoText: '',
  }

  const tempObj:Record<string, hostNameObjType> = {}

  return tempObj[hostname] || defaultObj
}


export default useGetHostnameObj