/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import Header from '@/components/header/header'
import styles from './register-agreement.module.scss'
import classNames from 'classnames'
import { getParams } from '@bihu/common-js'
import getHostNameObj from '@/hooks/get-host-name-obj'

interface Props{}
// 注册协议
const RegisterAgreement:React.FC<Props> = props => {
  const hostNameObj = getHostNameObj()

  const [isShowHeader, setIsShowHeader] = useState(false)
  const [name] = useState(hostNameObj.appName)

  useEffect(() => {
    if (getParams(undefined, 'isShowHeader')) {
      setIsShowHeader(true)
    }
  }, [])

  // 星辰钱包注册协议
  const youqianlaiRegister = () => {
    return (
      <>
        <p>
          您正在访问星辰钱包，星辰钱包依照以下协议内容向您提供本协议涉及的相关服务。请您使用星辰钱包前仔细阅读本协议。若要继续访问和使用星辰钱包，您必须不加修改地完全接受本网站的用户注册协议中所包含的各项条款政策以及指南，并同意星辰钱包即时刊登的通告，并且遵守中国政府有关互联网的相关法律政策，以及遵守本网站制定的各项规定与规则。如对本协议存有任何异议或疑问请您不要注册或使用星辰钱包的相关服务。您一旦注册或使用星辰钱包的服务，即视为您已理解并完全同意本协议各项内容，包括星辰钱包对用户注册协议随时做的任何修改。任何变更或修改将在修订内容于星辰钱包发布之时立即生效，您对星辰钱包的使用将表明您接受此变更或修改。如您不同意本协议及/或不同意星辰钱包有权随时对本协议进行修改，您应当停止访问星辰钱包并放弃选星辰钱包提供的服务。
        </p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>前言：星辰钱包是成都星辰同福企业管理有限公司旗下产品。我们专注于为小微企业主、个体工商户及工薪阶层提供快速、简便的小额信贷技术服务，我们将与我们的各类合作方助力于解决他们融资难的社会问题。</p>
        <div className={styles.emptyLine}></div>
        <p>一、特别提示</p>
        <p>
          1.1 我公司严格遵守现行法律法规，特制定《用户注册协议》(以下称为《本协议》) ，使用人 (以下称“用户”或“您”)应当基于了解本协议全部内容，在独立思考的基础上认可司意本协议的全部条款并按照页面上的提示完成对协议的确认后开始使用本服务。
        </p>
        <p className={styles.bold}>1.2 本协议项下以加粗字体或下划线展示的条款均需您在确认本协议之前仔细阅读和特别注意。</p>
        <div className={styles.emptyLine}></div>
        <p>二、服务内容</p>
        <p>
          2.1 本服务是由我公司提供的一款互联网平台工具。本服务可应用于Android、iOS、Windows等操作系统。
        </p>
        <p>
          2.2 用户可以采用本服务认可的有效信息 (如手机号、证件号、合作方账号及其他本软件认可的网络、社交、电商账号等) 注册、登录并使用本服务。在申请其他金融产品时，还需同时满足各合作方的要求。
        </p>
        <p>
          2.3 用户能享受的服务包括但不限于: 贷款及其他金融产品的推送、搜索、查询、匹配、申请、审核、放款、资金到账、账户管理等，其具体实现取决于本服务的版本更新及合作方的同意，具体功能请以本服务软件程序上的展示为准。
        </p>
        <p>2.4 本服务用户承诺以下事项:</p>
        <p className={styles.bold}>2.4.1 您必须提供个人真实、有效、完整且最新的本服务所需的必要资料。</p>
        <p className={styles.bold}>2.4.2 您有义务通过本服务维持并更新本服务所需的相关必要资料。若您提供任何虚假、错误、不完整或过时的资料或者我公司及其合作方发现为虚假、错误、不完整或过时我公司及相关合作方有权暂停或终止本服务，并拒绝您使用本服务的部分或全部功能，如由此产生的损失，由您自行承担。</p>
        <p className={styles.bold}>2.4.3 如因您未及时更新本服务所需的相关必要资料，导致本服务无法提供，您不得将此作为取消已发生交易或拒绝还款的理由，相应后果由您自行承担。</p>
        <div className={styles.emptyLine}></div>
        <p>三、隐私政策</p>
        <p>
          3.1 星辰钱包用户注册成功后，将被分配一个用户名及密码，该信息由用户自行负责保管，用户发布任何信息均属于个人意见，并不代表星辰钱包、星辰钱包运营商或星辰钱包关联公司的意见，用户应对以其用户帐号进行的所有活动和事件负法律责任。
        </p>
        <p>
          3.2 因黑客行为、计算机病毒侵入等自身原因致星辰钱包帐号、密码被他人非法盗用、篡改导致损失的，星辰钱包不承担赔付责任。如您发现任何非法使用用户帐号或安全漏洞的情况，请立即与星辰钱包联系，一旦核实采纳，星辰钱包将给予一定的奖励。
        </p>
        <p>
          3.3 星辰钱包用户的访问 IP 和交易行为，有可能被星辰钱包的服务器予以跟踪用户，但这些措施仅仅只是出于安全的必要。如果星辰钱包没有发现任何安全问题，会及时删除收集到的 IP 地址。
        </p>
        <p>
          3.4 用户理解，您同意向星辰钱包提供您的个人信息。为向您提供更加高效精准的服务，星辰钱包有权向入驻我司的合作认证机构对您的个人信息进行一定范围内的非公开脱敏精准推送，您特此向星辰钱包授权。为提供服务之目的，星辰钱包有权使用您的个人信息，您申请服务时提供相关信息和您在使用服务时储存星辰钱包的非公开内容（以下简称“个人资料”）。星辰钱包保证在除为提供服务之目的外，不向第三方提供您的个人资料，但下列情况除外：（1）事先已获得您授权的；（2）按照政府主管部门或相关法律要求应当披露的 ；（3）只有透露您的个人资料，才能提供您所要求的产品和服务的；（4）以维护星辰钱包合法权益之目的；（5）维护社会公众利益的；（6）其他符合法律要求的。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>四、用户守则</p>
        <p className={styles.bold}>
          4.1 用户在使用星辰钱包服务过程中，必须遵循国家的相关法律法规，任何下列行为的发布、上传、散播或以其他方式均不被允许：（1）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的，煽动民族仇恨、民族歧视，地域仇恨、地域歧视、破坏民族和人民团结的；（2）损害国家荣誉和利益的，散布谣言，扰乱社会秩序，破坏社会稳定的；（3）破坏国家宗教政策，宣扬邪教和封建迷信的；（4）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的、欺诈性的信息或其他令人反感的讯息、数据、信息、文本、音乐、声音、照片、图形、代码或其他材料；（5）侮辱或者诽谤他人，侵害他人合法权益的；（6）违反宪法和法律、行政法规或规章制度的；（7）违反宪法和法律、行政法规的基本原则的；（8）可能侵犯他人的专利、商标、商业秘密、版权或其他知识产权或专有权利的内容；（9）假冒任何人或实体或以其他方式歪曲您与任何人或实体之关联性的内容；（10）未经请求而擅自提供的促销信息、政治活动、广告或意见征集；（11）任何第三方的私人信息，包括但不限于地址、电话号码、电子邮件地址、身份证号以及信用卡卡号；（12）病毒、不可靠数据或其他有害的、破坏性的或危害性的文件；（13）与内容所在的互动区域的话题不相关的内容；（14）依星辰钱包的自行判断，足以令人反感的内容，或者限制或妨碍他人使用或享受互动区域或星辰钱包的内容，或者可能使星辰钱包或星辰钱包关联方或其他用户遭致任何类型损害或责任的内容；（15）未经授权，披露其他任何第三方信息；（16）包含法律或行政法规禁止内容的其他内容。
        </p>
        <p className={styles.bold}>
          4.2 用户不得利用星辰钱包的服务从事下列危害计算机信息网络安全的活动：（1）未经授权进入踏入计算机信息网络或者使用计算机信息网络资源；（2）未经授权对他人计算机信息网络功能进行删除、修改或者增加；（3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加；（4）任何有意或无意制作、传播计算机病毒等破坏性程序；（5）其他危害计算机及网络安全的行为。
        </p>
        <p className={styles.bold}>
          4.3 星辰钱包保留在任何时候为任何理由而不经通知地过滤、移除、筛查或编辑星辰钱包上发布或存储的任何内容的权利，您须自行负责备份和替换在本网站发布或存储的任何内容，成本和费用自理；
        </p>
        <p className={styles.bold}>
          4.4 您须对自己在使用星辰钱包服务过程中的行为承担法律责任。若您为限制行为能力或无行为能力者，则您的法定监护人应承担相应的法律责任。
        </p>
        <p className={styles.bold}>
          4.5 如您的操作影响系统总体稳定性或完整性，星辰钱包将暂停或终止您的操作，直到相关问题得到解决。
        </p>

        <div className={styles.emptyLine}></div>
        <p>五、免责声明</p>
        <p>
          5.1 用户明确同意因其自身原因导致使用星辰钱包所存在的风险将由其自己承担，因其自身原因导致使用星辰钱包而产生的相应法律后果也由其自己承担，因我公司责任导致的除外。
        </p>
        <p>
          5.2 对于因不可抗力或我公司不能控制的原因造成的本软件所提供的服务中断或其它缺陷，我公司不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。上述状况包括但不限于：
        </p>
        <p>
          5.2.1 在本服务公告之系统停机维护期间；
        </p>
        <p>
          5.2.2 您自身的设备或非因可归责于我公司原因导致的我公司设备出现故障不能进行数据传输；
        </p>
        <p>
          5.2.3 因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成系统障碍不能执行任务的；
        </p>
        <p>5.2.4 由于电信部门技术调整或故障、发布公告后的软件升级、银行方面的问题等原因而造成的服务中断或延迟。</p>
        <p>5.3 对黑客行为、计算机病毒、或因您保管疏忽致使帐号、密码被他人非法使用、盗用、篡改的或丢失，或由于与本网站链接的其他网站所造成您个人资料的泄露，星辰钱包不承担责任。如您发现任何非法使用用户帐号或安全漏洞的情况，请立即与星辰钱包联系。</p>
        <p className={styles.bold}>
          5.4 任何情况下，因使用星辰钱包而引起或与使用星辰钱包有关的而产生的由星辰钱包负担的责任总额，无论是基于合同、保证、侵权、产品责任、严格责任或其他理论，均不得超过您因访问或使用本网站而向星辰钱包支付的任何报酬（如果有的话）。
        </p>
        <p>
          5.5 星辰钱包会根据实际情况随时调整星辰钱包平台提供的服务种类、形式。星辰钱包保留变更、中断或终止部分网络服务的权利，星辰钱包将尽可能在网站上进行通告。我们不承担因业务调整或暂停网络服务而给用户造成的损失。
        </p>
        <p className={styles.bold}>
          5.6 发生下列任何一种情形，星辰钱包有权单方面中断或终止向您提供服务而无需通知您，且无需对您或第三方承担任何责任：（1）您提供的个人资料不真实；（2）您违反本协议任意条款 （3）未经星辰钱包书面同意，将星辰钱包平台用于商业目的。
        </p>
        <p>
          5.7 自您终止或取消服务之日起，星辰钱包不再向您承担任何形式的责任。
        </p>
        <div className={styles.emptyLine}></div>
        <p>六、服务变更、中断或终止</p>
        <p>6.1 如因升级的需要而需暂停网络服务、或调整服务内容，星辰钱包将尽可能在网站上进行通告，请您留心查看。</p>
        <p>6.2 您明确同意，星辰钱包保留根据实际情况随时调整星辰钱包提供的服务内容、种类和形式，或自行决定授权第三方向您提供原本星辰钱包提供的服务。</p>
        <p>6.3 您可随时通知星辰钱包终止向您提供服务或直接取消星辰钱包服务。</p>
        <div className={styles.emptyLine}></div>
        <p>七、知识产权</p>
        <p>本服务 (包括但不限于所含的任何图像、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料) 的一切权利均属于我公司，未经我公司许可，用户不得对该软件进行反向工程 (reverse engineer)、反向编译 (decompie) 或反汇编 (disassemble) ，也不得对我公司享有知识产权的对象进行未经许可的使用、传播或任何侵犯本软件知识产权的行为。</p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>八、特别约定</p>
        <p className={styles.bold}>8.1 您使用本服务的行为若有任何违反国家法律法规或侵犯任何第三方的合法权益的情形时，星辰钱包有权直接删除该等违反规定之信息，并可以暂停或终止向您提供服务。</p>
        <p className={styles.bold}>8.2 若您利用星辰钱包服务从事任何违法或侵权行为，由您自行承担全部责任，因此给星辰钱包或任何第三方造成任何损失，您应负责全额赔偿，并使星辰钱包免受由此产生的任何损害。</p>
        <p className={styles.bold}>8.3 您同意星辰钱包通过重要页面的公告、通告或电子邮件或常规信件的形式向您传送与星辰钱包服务有关的任何通知和通告。</p>
        <p className={styles.bold}>8.4 星辰钱包有权随时修改用户注册协议，用户在使用星辰钱包时，有必要对最新的星辰钱包用户注册协议进行仔细阅读和重新确认，当发生有关争议时，请以最新用户注册协议为准；</p>
        <p className={styles.bold}>8.5 本协议之效力、解释、执行均适用中华人民共和国法律。</p>
        <p className={styles.bold}>8.6 如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，任何一方均可向本协议签署地有管辖权的人民法院提起诉讼。</p>
        <p className={styles.bold}>8.7 本协议签署地为广州市天河区。</p>
        <p className={styles.bold}>8.8 本协议最终的解释权归星辰钱包所有。</p>
      </>
    )
  }

  return (
    <div className={classNames(styles.registerAgreement, isShowHeader && styles.isShowHeader)}>
      {
        isShowHeader && <Header title="注册协议" />
      }
      {
        youqianlaiRegister()
      }
    </div>
  )
}

export default RegisterAgreement