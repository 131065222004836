/* eslint-disable no-magic-numbers */
import React, { useCallback, useState, useEffect, useRef } from 'react'
import styles from './normal-skin.module.scss'
import classNames from 'classnames'
import { Button } from 'antd-mobile'
interface Props {
  productName?: '', // 产品名字
  applyStatus?: number, // hold单状态
  rePageShowType?: number,
  thirdpartyApiType?: number,
  thirdpartyType?: number,
}

const NormalSkin: React.FC<Props> = props => {
  const { productName, rePageShowType } = props

  return (
    <div className={styles.fullPage}>
      <div className={styles.main}>
        <div className={styles.logoBox}>
          <img className={styles.logo} src={require('@imgs/xzqb-logo.png')} alt="logo" />
        </div>
        {
          productName ? <>
            <div className={styles.applyResultBox}>
              <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
              <div className={styles.applyResultBoxRight}>
                <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.active}>{productName}</span>申请成功！</div>
                <div className={styles.applyResultBoxText2}>请留意审核人员来电，完成信息审核</div>
              </div>
            </div>
            <div className={classNames(styles.containers)}>
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/pass.png')} alt="pass" />
                <div className={styles.containerItemSuccessText}>预审通过</div>
              </div>
              <img className={styles.line} src={require('@imgs/form-result/line.png')} alt="line" />
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/phone.png')} alt="phone" />
                <div className={styles.containerItemFailText}>请留意审核人员来电</div>
              </div>
            </div>
          </> : <>
            <div className={styles.applyResultBox}>
              <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
              <div className={styles.applyResultBoxRight}>
                <div className={styles.applyResultBoxText1}><span className={styles.active}>您已预审通过</span></div>
                <div className={classNames(styles.applyResultBoxText2, styles.smallText)}>抱歉，当前申请名额已满</div>
              </div>
            </div>
            <div className={classNames(styles.containers)}>
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/pass.png')} alt="pass" />
                <div className={styles.containerItemSuccessText}>预审通过</div>
              </div>
              <img className={styles.line} src={require('@imgs/form-result/line.png')} alt="line" />
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/phone.png')} alt="phone" />
                <div className={styles.containerItemFailText}>抱歉，当前申请名额已满</div>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default NormalSkin
