/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import styles from './success.module.scss'
import { isIOS, isInWeChat, isMobile, download, Storage } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import { useSearchParams } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import copy from 'copy-to-clipboard'
import IosSkin from './components/ios-skin/ios-skin'
import NormalSkin from './components/normal-skin/normal-skin'

interface Props{}

const Success:React.FC<Props> = () => {
  const [search] = useSearchParams()
  const options:any = search.get('options')
  let resData:any = {}
  if (options) {
    try {
      resData = JSON.parse(decodeURIComponent(options))
    } catch {
      resData = JSON.parse(options)
    }
  }
  const { productName } = resData
  const { thirdpartyApiType } = resData
  const { thirdpartyType } = resData
  const { thirdpartyTargetUrl } = resData
  const { isNotFormReq } = resData
  let { applyStatus } = resData //是否hold单，状态为8代表hold单
  // let { revisit } = resData // 用户已授权状态下，再次访问结果页的标识(非第一回)
  let revisit = true // 暂时去掉再访状态的来源获取，默认为true
  const { isSpecialListCity } = resData // 是否来自特殊城市的用户 || 注册-下载模式的用户

  /** rePageShowType 0/null 不处理 1：取消下载需求 2：引导公众号需求 */
  let { rePageShowType } = resData

  // 判断是否百度渠道的分发页
  const isBaiduChannels = localStorage.getItem('isBaiduChannels')
  if (isBaiduChannels) {
    rePageShowType = 2 //如果是的话，展示和分期乐一样的结果页
  }

  // 渠道详情
  const [codeDetail, setCodeDetail] = useState<any>()
  //isPageSetup 为true时 结果页无只有分发结果，无H5产品和下载
  const [isPageSetup, setIsPageSetup] = useState(false)

  // 渠道编码
  const [adChannelCode, setAdChannelCode] = useState('')

  useEffect(() => {
    const getChannelDetail = async() => {
      const codeDetailRes:any = await activityApi.getChannelCodeDetail(adChannelCode)
      setCodeDetail(codeDetailRes)
      if (codeDetailRes.pageSetup) {
        setIsPageSetup(true)
      }
    }
    if (adChannelCode) {
      getChannelDetail()
    }
  }, [adChannelCode])

  useEffect(() => {
    // 获取最新一次存储的渠道码
    const lastAdChannelCode = localStorage.getItem('ad_channel_code')
    if (lastAdChannelCode) {
      setAdChannelCode(lastAdChannelCode)
    }
  }, [])

  const otherResultComponent = () => {
    return (
      <div>
        <NormalSkin
          productName={productName}
          rePageShowType={rePageShowType}
        ></NormalSkin>
        {/* {
          rePageShowType === 1 || (rePageShowType !== 2 && !isIOS && applyStatus !== 8 && !revisit && !isPageSetup && !isSpecialListCity) ? <>
            <NormalSkin
              productName={productName}
              rePageShowType={rePageShowType}
            ></NormalSkin>
          </> : <>
            <IosSkin productName={productName} applyStatus={applyStatus} revisit={revisit}></IosSkin>
          </>
        } */}
      </div>
    )
  }

  return (
    <div>
      {codeDetail && otherResultComponent()}
    </div>
  )
}

export default Success