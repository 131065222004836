/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import styles from './rematch-waiting.module.scss'
import Header from '@/components/header/header'
import classNames from 'classnames'
import ProductList from '@/components/product-list/product-list'
import productApi, { ProductListResponse, ProductListParams } from '@/apis/product'
import { ENTRANCE_TYPE } from '@/constants/common'
import { isIOS, isInWeChat, isMobile, download } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import { useSearchParams } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import copy from 'copy-to-clipboard'

interface Props{}

const WaitingSuccess:React.FC<Props> = () => {
  const [search] = useSearchParams()
  const options:any = search.get('options')
  const resData = JSON.parse(decodeURIComponent(options))

  // 下载倒计时
  const [countdownTime, setCountdownTime] = useState<number>(3)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  // 倒计时
  useEffect(() => {
    if (isMobile) {
      if (countdownTime > 0) {
        timer.current = setTimeout(() => {
          setCountdownTime(countdownTime - 1)
        }, 1000)
      } else {
        // 惠逸花授权页
        if (resData.rqResult.thirdpartyType === 5) {
          window.location.replace(`/fill-information/authorize?options=${encodeURIComponent(JSON.stringify(resData.rqResult))}&adChannelCode=${resData.adChannelCode}&isHuaWeiPhone=${resData.isHuaWeiPhone}&applyLimit=${resData.applyLimit}`)
          return
        }
        // 公用授权页
        window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(resData))}`)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime])

  return (
    <section className={styles.authorizePage}>
      <Header title="星辰钱包" />

      <img className={styles.rematchGif} src={require('@imgs/gif/rematch.gif')} />
    </section>
  )
}

export default WaitingSuccess