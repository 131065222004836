// 有信详情页
import React, { useState, useEffect } from 'react'
import styles from './you-xin-detail.module.scss'
import { useSearchParams } from 'react-router-dom'
import Header from '@/components/header/header'
import classNames from 'classnames'
import { Popup, Button, Input, Toast } from 'antd-mobile'
// import Icon from '@/components/icon/icon'
import { parseNum, Storage } from '@bihu/common-js'
import { TOKEN } from '@/constants/storage'
// import DetainmentPopup, { DetainmentPopupProps } from '@/components/detainment-popup/detainment-popup'
import indexApi from '@/apis'
import BigNumber from 'bignumber.js'
import ChooseOption from '@/pages/fill-information/components/choose-option/choose-option' // 选择选项
import { OptionType, creditSesameOptions } from '@/pages/fill-information/options'

interface Props {}

const LoanApplication: React.FC<Props> = props => {

  // const navigate = useNavigate()
  const [search] = useSearchParams()

  // const [token, setToken] = useState('')
  // 产品
  const [product] = useState({
    loansLimitMin: 5000, //借款额度下限
    loansLimitMax: 200000, //借款额度上限
    monthlyInterestRate: 1, //月利率
    // askCondition: [{ label: '手机运营商' }],
    introduction: '正规持牌机构易通过最快3分钟放款',
    productCompany: '海南柠坤科技有限公司',
    contactNumber: '备案信息：琼ICP备2022018381号-1'
  })
  // 借款金额
  const [amount, setAmount] = useState<string>('50,000')


  // 挽留弹窗ref
  // const detainmentPopupRef = useRef<DetainmentPopupProps>(null)

  // 返回
  // const back = () => {
  //   const productBack = localStorage.getItem('productBack')
  //   // console.log('productBack', productBack)

  //   try {
  //     if (productBack === formatTime(Date.now(), 'YYYY-MM-DD')) {
  //       if (document.referrer) {
  //         navigate(-1)
  //       } else {
  //         window.location.href = '/'
  //       }

  //       detainmentPopupRef.current?.close()
  //     } else {
  //       //  阻止浏览器回退事件
  //       detainmentPopupRef.current?.show()
  //       window.history.pushState(null, 'null', document.URL)
  //       localStorage.setItem('productBack', formatTime(Date.now(), 'YYYY-MM-DD'))
  //     }
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    // 获取产品名称
    const tokenTemp = search.get('token')
    if (tokenTemp) {
      Storage.set(TOKEN, tokenTemp)
    }

    // localStorage.removeItem('productBack')
    // // 监听浏览器回退事件
    // // window.addEventListener('popstate', back, false)
    // window.onpopstate = back
    // window.history.pushState(null, 'null', document.URL)

    // return () => {
    //   // 回退事件只用于当前组件，则需要在组件销毁时把回退事件销毁
    //   // window.removeEventListener('popstate', back, false)
    //   window.onpopstate = null
    // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 是否显示弹出层
  const [visible, setVisible] = useState(false)
  // 当前选中月份
  // eslint-disable-next-line no-magic-numbers
  const [currentMonth, setCurrentMonth] = useState<number>(12)

  // 月份数组
  const monthArr = [
    {
      key: 1,
      num: 3,
    },
    {
      key: 2,
      num: 6,
    },
    {
      key: 3,
      num: 12,
    },
    {
      key: 4,
      num: 24,
    },
    {
      key: 5,
      num: 36,
    },
  ]

  // 选择月份
  const selectMonth = (num: number) => {
    setCurrentMonth(num)
    setVisible(false)
  }

  // 借款金额改变
  const amountOnChange = (str: string) => {
    str = str.replace(/[^\d]/g, '')
    let newValue:number | string = str
    if (product) {
      if (+str > product.loansLimitMax) {
        newValue = product.loansLimitMax
      }
    }
    newValue = parseNum(newValue as number)
    if (newValue && newValue !== '0') {
      setAmount(newValue)
    } else {
      setAmount('')
    }
  }

  // 每月需还金额
  const [monthlyRepaymentAmount, setMonthlyRepaymentAmount] = useState<number>(0)

  useEffect(() => {
    if (amount && product && currentMonth) {
      const amountNew = amount.replace(',', '')
      const monthRate = new BigNumber(product.monthlyInterestRate).div(100)

      const powNum = new BigNumber(1).plus(monthRate)
        .pow(currentMonth)
      let val = new BigNumber(amountNew).multipliedBy(monthRate)
        .multipliedBy(powNum)
        .div(powNum.minus(1))
        .toFixed(2)
      setMonthlyRepaymentAmount(Number(val))
    } else {
      setMonthlyRepaymentAmount(0)
    }
  }, [currentMonth, amount, product])

  // 预估利息 = 每月还款额*期数-借款金额
  const [repaymentInterest, setRepaymentInterest] = useState<number>(0)
  useEffect(() => {
    if (currentMonth && amount && monthlyRepaymentAmount) {
      const amountNew = amount.replace(',', '')

      const val = new BigNumber(monthlyRepaymentAmount).multipliedBy(currentMonth)
        .minus(amountNew)
        .toFixed(2)

      setRepaymentInterest(Number(val))
    }
  }, [currentMonth, amount, monthlyRepaymentAmount])

  // 是否展开
  const [initIsExpansion] = useState(true)

  // 芝麻分
  const [sesameCredit, setCreditSesame] = useState<string>('2')
  const creditSesameOnChange = (obj:OptionType) => {
    setCreditSesame(obj.value)
  }

  // 立即申请
  const apply = async() => {
    if (!amount) {
      return Toast.show('请输入借款金额')
    }
    // console.log('立即申请', amount, amount.replace(',', ''))
    let newAmount = parseInt(amount.replace(',', '') || '0')
    // console.log('newAmount', newAmount)


    if (newAmount < product.loansLimitMin) {
      return Toast.show(`借款金额不能小于${parseNum(product?.loansLimitMin)}`)
    }

    if (!currentMonth) {
      return Toast.show('请选择借款时长')
    }

    if (!sesameCredit) {
      return Toast.show('请选择芝麻分')
    }

    const params = {
      sesameCredit, // 芝麻分
    }
    Toast.show({
      icon: 'loading',
      content: '申请中…',
      duration: 0
    })
    localStorage.setItem('fillInformation', JSON.stringify(params))
    await indexApi.tempSave(params)
    Toast.clear()
    window.location.href = '/fill-information?from=youxin'
  }

  // interface AskConditionType{
  //   label: string
  // }

  return (
    <div className={styles.loanApplication}>
      <section className={styles.top}>
        <Header title={'星辰钱包'} className={styles.header} />
        <div className={classNames(styles.borrowBox)}>
          {
            product
            && <div className={styles.describe}>借多少({parseNum(product?.loansLimitMin)}-{parseNum(product?.loansLimitMax)}元)</div>
          }

          <div className={styles.inputBox}>
            <span>
                ￥
            </span>
            <Input placeholder="" value={amount} onChange={amountOnChange} />
          </div>
          <div className={styles.selectMonthBox}>
            <div>借多久</div>
            <div className={classNames(styles.select, currentMonth && styles.currentMonth)} onClick={() => setVisible(true)}>
              {
                currentMonth ? `${currentMonth} 个月` : '请选择'
              }
            </div>
          </div>
        </div>
      </section>

      <section className={classNames(styles.block, styles.calculateBox)}>
        <div>
          <div className={styles.label}>参考月利率</div>
          <div className={styles.value}>{product?.monthlyInterestRate}%</div>
        </div>

        <div>
          <div className={styles.label}>预估利息(元)</div>
          <div className={classNames(styles.value, !repaymentInterest && styles.grayColor)}>{repaymentInterest ? parseNum(repaymentInterest) : '-'}</div>
        </div>
        <div>
          <div className={styles.label}>月均还款(元)</div>
          <div className={classNames(styles.value, !monthlyRepaymentAmount && styles.grayColor)}>
            {monthlyRepaymentAmount ? parseNum(monthlyRepaymentAmount) : '-'}
          </div>
        </div>
      </section>

      <section className={classNames(styles.block, styles.creditSesame)}>
        <ChooseOption
          name="芝麻分"
          onChange={creditSesameOnChange}
          options={creditSesameOptions}
          initIsExpansion={initIsExpansion}
          value={sesameCredit}
          isSupportExpansion={false}
        />
      </section>

      <section className={styles.block}>
        {/* <div className={classNames(styles.title, styles.marginTop0)}>申请条件</div>
        <div className={styles.askCondition}>
          {
            product?.askCondition && product.askCondition.map((item:AskConditionType) => {
              return <div key={item.label}>
                <Icon type="check-circle" className={styles.checkCircle} />
                <span className={styles.name}>{item.label}</span>
              </div>
            })
          }
        </div> */}
        <div className={styles.title}>产品介绍</div>
        <div className={styles.content}>{product?.introduction}</div>
        <div className={styles.title}>机构信息</div>
        <div className={styles.content}>公司主体：{product?.productCompany}</div>
        <div className={styles.content}>{product?.contactNumber || '-'}</div>
      </section>

      <footer className={styles.footer}>
        <Button className={styles.btn} onClick={apply}>立即申请</Button>
      </footer>

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
      >
        <ul className={styles.monthBox}>
          {
            monthArr.map(item => {
              return <li
                key={item.key}
                className={classNames(styles.row, item.num === currentMonth && styles.active)}
                onClick={() => selectMonth(item.num)}
              >
                {item.num} 个月
              </li>
            })
          }
        </ul>
      </Popup>

      {/* <DetainmentPopup ref={detainmentPopupRef} /> */}
    </div>
  )
}

export default LoanApplication
